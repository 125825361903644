import Img2019_1 from '../img/jury/data2 2019_1.jpg'
import Img2019_2 from '../img/jury/data2 2019_2.jpg'
import Img2019_3 from '../img/jury/data2 2019_3.jpg'
import Img2020_1 from '../img/jury/data2 2020_1.jpg'
import Img2020_2 from '../img/jury/data2 2020_2.jpg'
import Img2020_3 from '../img/jury/data2 2020_3.jpg'
import Img2021_1 from '../img/jury/data2 2021_1.jpg'
import Img2021_3 from '../img/jury/data2 2021_3.jpg'
import Img2021_4 from '../img/jury/IMG_0716.jpg'
import Img2022_1 from '../img/jury/data2 2022_1.jpg'
import Img2022_2 from '../img/jury/2020_3.jpg'
import Img2022_3 from '../img/jury/data2 2022_3.jpg'

const data2 = [
    {
      year: 2019,
      items: [
        {
          name: 'ВИКТОРИЯ ЩЕРБИНА',
          country: 'Испания',
          image: Img2019_1,
          text1: 'Учредитель школы и салона красоты Lashmaker y Nails beauty "Victoria Art Beauty" (Испания)',
          text2: 'Преподаватель ногтевого и ресничного наращивания с 2010 года',
          text3: 'Международный преподаватель KODI PROFESSIONAL по наращиванию ногтей и ресниц',
          text4: 'Неоднократный победитель международных и региональных чемпионатов в Испании',
          text5: 'Организатор двух первых международных чемпионатов "Star Lashes by KODI PROFESSIONAL"',
          text6: 'Дистрибьютор марки KODI PROFESSIONAL в Испании',
        },
        {
            name: 'АННА ПОПОВА',
            country: 'Португалия',
          image: Img2019_2,
          text1: 'Международный инструктор-преподаватель KODI PROFESSIONAL',
          text2: 'Международный судья INJA. Официальный инструктор INJA',
          text3: 'Двукратный чемпион Украины',
          text4: 'Серебряный призёр чемпионата Европы OMC',
          text5: 'Многократный призёр и победитель всеукраинских и международных чемпионатов',
          text6: 'Разработчик авторских техник по маникюру, моделированию и дизайну ногтей',
        },
        {
            name: 'АННА АВРАМЕНКО',
            country: 'Украина',
          image: Img2019_3,
          text1: ' Вице-президент Ассоциации Бьюти-Чемпионатов (ABC) по направлению ногтевой эстетики (Киев)',
          text2: ' Cудья международного уровня',
          text3: 'Тренер команды Anna Avramenko Studio nails и "Atica Украина"',
          text4: 'Многократный победитель чемпионатов',
          text5: 'Технолог ТМ "VAM"',
          text6: 'Основатель центра повышения квалификации для мастеров ногтевого сервиса и перманентного макияжа "AVRAMENKO SCHOOL"',
        },
      ],
    },
    {
        year: 2020,
        items: [
          {
            name: 'АЛЕНА СМИРНОВА',
            country: 'Беларусь',
            image: Img2020_1,
            text1: 'Судья международной категории, сертифицированный судья чемпионатов',
            text2: 'Спикер, докладчик, автор статей в журналах',
            text3: 'Многочисленный победитель чемпионатов в категории "Premium"',
            text4: 'Собственные разработанные учебные программы курсов для обучения и тренингов по подготовке к чемпионатам',
            text5: 'Разработчик авторских техник дизайна, моделирования ногтей и маникюра',
            text6: 'Основатель студии "ASprofessional"',
          },
          {
            name: 'МАРКО БОНВИЧИНИ',
            country: 'Италия',
            image: Img2020_2,
            text1: 'Неоднократный победитель чемпионатов по ногтевой эстетике в Италии, Корее и Чехии',
            text2: 'Судья INJA 2017, Nailympion (Испания), Nailympion (Швейцария), Nailympion (Аргентина), INJA (Украина), INJA (Лугано)',
            text3: 'Международный тренер',
            text4: 'Совладелец марки Doctor Nails',
            text5: 'Организатор Чемпионата в Италии по наращиванию ногтей и Римского международного чемпионата по ногтевой эстетике',
            text6: 'Опыт работы в ногтевой сфере более 29 лет',
          },
          {
            name: 'ИННА ОРЕХОВА',
            country: 'Украина',
            image: Img2020_3,
            text1: 'Основатель "Nail Studio Inna Orekhova"',
            text2: 'Многократный чемпион украинских и международных чемпионатов',
            text3: 'Международный судья INJA',
            text4: 'Старейшина чемпионатов "BeautyWest". Инструктор компании "Real Professional"',
            text5: 'Активное развитие преподавательской деятельности с 2010 года',
            text6: 'Опыт работы 18 лет',
          },
        ],
      },
      {
        year: 2021,
        items: [
          {
            name: 'ЕЛЕНА КУШНИРЕНКО',
            country: 'Беларусь',
          image: Img2021_1,
          text1: 'Многократный победитель украинских и международных чемпионатов по ногтевому искусству',
          text2: ' Преподаватель Учебного центра "Студия стиля KODI" по направлению "Ногтевая эстетика',
          text3: 'Судья украинских и международных чемпионатов',
          text4: 'Основатель Beauty Studio (Чернигов)',
          text5: 'Активное развитие преподавательской деятельности с 2006 года',
          text6: 'Опыт работы 20 лет',
        },
          {
            name: 'Инга Аванесян',
            country: 'Украина',
            image: Img2021_4,
            text1: 'Победитель Международного конкурса «Звезды Красоты» 2020',
            text2: 'Победитель Международной премии «Олимп Красоты» в номинации «Инновации в процедурах» 2019 год',
            text3: 'Обладатель Патента на Полезную модель «Способ моделирования формы «Стилет» искусственного ногтя»',
            text4: 'Судья украинских и международных конкурсов',
            text5: 'Спикер на Международных конференциях',
            text6: 'Практикующий мастер со стажем работы более 10 лет',
          },
          {
            name: 'ИРИНА ЛЯСКОВСКАЯ',
            country: 'Украина',
            image: Img2021_3,
            text1: 'Чемпион Украины и Европы',
            text2: 'Международный инструктор-преподаватель Kodi Professional по ногтевой эстетике',
            text3: 'Aвтор и разработчик программ по ногтевой эстетике',
            text4: 'Многократный призёр украинских конкурсов',
            text5: 'Практикующий мастер с опытом работы 10 лет',
            text6: 'Инструкторский стаж 5 лет',
          },
        ],
      },
      {
        year: 2022,
        items: [
          {
            name: 'ГАЛИНА ГРИБ',
            country: 'Украина',
            image: Img2022_1,
            text1: 'Стилист, художник-дизайнер одежды и аксессуаров, fashion иллюстратор',
            text2: 'Основатель GalinaGrib',
            text3: 'Более 15 лет создает образы для чемпионов мира, артистов и VIP персон',
            text4: 'Победитель всеукраинских и международных конкурсов дизайнеров и fashion иллюстраторов',
            text5: 'Работы представлены во всех уголках мира: от Нью Йорка до африканских стран',
            text6: 'Опыт работы 20 лет',
          },
          {
            name: 'ОЛЬГА ГЛУШЕНКОВА',
            country: 'Украина',
            image: Img2022_2,
            text1: 'Международный мастер, инструктор-преподаватель, технолог KODI PROFESSIONAL',
            text2: 'Опыт работы в области перманентного макияжа и визажа более 13 лет',
            text3: 'Дипломированный имиджмейкер (Академия Константина Богомолова',
            text4: 'Бронзовый призер чемпионата Украины по стилю и визажу (2009-2010 г.г.)',
            text5: 'Активное развитие преподавательской деятельности с 2007 года',
            text6: 'Стажировка в школе Аtelier (Париж, 2006 г.)',
          },
          {
            name: 'АННА АДАМОВСКАЯ',
            country: 'Украина',
            image: Img2022_3,
            text1: 'Международный тренер-инструктор KODI PROFESSIONAL',
            text2: 'Судья чемпионатов международного уровня по ногтевой эстетике',
            text3: 'Ведущий технолог "Компании Коди" по ногтевой эстетике',
            text4: 'Разработчик авторских программ по дизайну ногтей',
            text5: 'Основатель студии "Beauty Room Friend’s"',
            text6: 'Опыт работы 15 лет',
          },
        ],
      },
      {
        year: 2023,
        items: [
          {
            name: 'АЛЕНА СМИРНОВА',
            country: 'Беларусь',
            image: Img2020_1,
            text1: 'Судья международной категории, сертифицированный судья чемпионатов',
            text2: 'Спикер, докладчик, автор статей в журналах',
            text3: 'Многочисленный победитель чемпионатов в категории "Premium"',
            text4: 'Собственные разработанные учебные программы курсов для обучения и тренингов по подготовке к чемпионатам',
            text5: 'Разработчик авторских техник дизайна, моделирования ногтей и маникюра',
            text6: 'Основатель студии "ASprofessional"',
          },
          {
            name: 'АННА ПОПОВА',
            country: 'Португалия',
          image: Img2019_2,
          text1: 'Международный инструктор-преподаватель KODI PROFESSIONAL',
          text2: 'Международный судья INJA. Официальный инструктор INJA',
          text3: 'Двукратный чемпион Украины',
          text4: 'Серебряный призёр чемпионата Европы OMC',
          text5: 'Многократный призёр и победитель всеукраинских и международных чемпионатов',
          text6: 'Разработчик авторских техник по маникюру, моделированию и дизайну ногтей',
          },
          {
            name: 'ИРИНА ЛЯСКОВСКАЯ',
            country: 'Украина',
            image: Img2021_3,
            text1: 'Чемпион Украины и Европы',
            text2: 'Международный инструктор-преподаватель Kodi Professional по ногтевой эстетике',
            text3: 'Aвтор и разработчик программ по ногтевой эстетике',
            text4: 'Многократный призёр украинских конкурсов',
            text5: 'Практикующий мастер с опытом работы 10 лет',
            text6: 'Инструкторский стаж 5 лет',
          },
        ],
      },
  ];

  export default data2;
