import Img2019_1 from '../img/jury/data5 2019_1.jpg'
import Img2019_2 from '../img/jury/data5 2019_2.png'
import Img2019_3 from '../img/jury/data5 2019_3.jpg'
import Img2020_2 from '../img/jury/data5 2020_2.jpg'
import Img2020_3 from '../img/jury/data5 2020_3.jpg'
import Img2021_1 from '../img/jury/data5 2021_1.jpg'
import Img2021_2 from '../img/jury/data5 2021_2.jpg'
import Img2021_3 from '../img/jury/data5 2021_3.png'
import Img2022_1 from '../img/jury/data5 2022_1.jpg'
import Img2022_2 from '../img/jury/data5 2022_2.jpg'
import Img2022_3 from '../img/jury/data5 2022_3.jpg'
import Img2023_1 from '../img/jury/data5 2023_1.jpg'
import Img2023_2 from '../img/jury/data5 2023_2.jpg'
import Img2023_3 from '../img/jury/data5 2023_3.jpg'

const data5 = [
    {
      year: 2019,
      items: [
        {
          name: 'Алина Гокк',
          country: 'Россия',
          image: Img2019_1,
          text1: 'Ведущий мастер неотрадиционной татуировки',
          text2: 'Основатель сообщества Neotraditional Russia, тату-коллектива NTR и школы addatattoo School',
          text3: 'Обожает делать портреты с элементами модерна',
          text4: 'Обучает мастеров рисунку и повышает квалификацию мастеров в неотраде',
        },
        {
            name: 'Максим Кислицын',
            country: 'Россия',
          image: Img2019_2,
          text1: 'Мастер японской ориентальной татуировки',
          text2: 'Участник и судья многих тату-фестивалей по всему миру',
          text3: 'Недавно Максим основал свой проект — TANTO STUDIO, где собралась команда из опытных мастеров',
          text4: 'В особом представлении этот человек не нуждается, ведь его имя давно стало знаком качества в тату-индустрии',
        },
        {
            name: 'Александр Сенцов',
            country: 'Россия',
            image: Img2019_3,
            text1: 'Работает в стиле Realistic Trash Polka',
            text2: 'Призёр Российских и зарубежных Татуировки Конвенций',
            text3: 'Участник и судья многих тату-фестивалей по всему миру более 10 лет',
            text4: 'Татуировкой занимаюсь с 2001 года',
        },
      ],
    },
    {
        year: 2020,
        items: [
          {
            name: 'Максим Кислицын',
            country: 'Россия',
          image: Img2019_2,
          text1: 'Мастер японской ориентальной татуировки',
          text2: 'Участник и судья многих тату-фестивалей по всему миру',
          text3: 'Недавно Максим основал свой проект — TANTO STUDIO, где собралась команда из опытных мастеров',
          text4: 'В особом представлении этот человек не нуждается, ведь его имя давно стало знаком качества в тату-индустрии',
          },
          {
            name: 'Антон Ковригин',
            country: 'Россия',
            image: Img2020_2,
            text1: 'Работает с 2008 года',
            text2: 'Первый начал популизировать акварельный стиль в России (с 2011г)',
            text3: 'Сейчас в основном делает своих персонажей развивая свою вселенную',
            text4: 'Работы Антона всегда отличаются яркостью и нестандартностью',
          },
          {
            name: 'Сергей Раков',
            country: 'Россия',
            image: Img2020_3,
            text1: 'Мастер художественной татуировки',
            text2: 'Участник и судья многих тату-фестивалей по всему миру',
            text3: 'Участник международных выставок, спикер конференций и форумов по тату',
            text4: 'Стаж работы более 15 лет',
          },
        ],
      },
      {
        year: 2021,
        items: [
          {
            name: 'Дмитрий Перунов',
            country: 'Украина',
          image: Img2021_1,
          text1: ' Мастер чёрно-белого реализма',
          text2: 'Участник международных выставок, спикер конференций и форумов по тату',
          text3: 'Приезжает в города и дает групповые мастер классы',
          text4: 'Татуировкой занимается  более 10 лет',
        },
          {
            name: 'Василий Суворов',
            country: 'Россия',
            image: Img2021_2,
            text1: 'Побеждал на фестивалях в цветной татуировке Нью-Йорке, Лос-Анджелесе, Нью-Джерси и тд',
            text2: 'Участвовал в проекте Райна Смита коллаборации лучших тату мастеров мира',
            text3: 'Организатор первых в России онлайн Вебинаров по цветной татуировке ',
            text4: 'Занимается прокачкой тату студий',
          },
          {
            name: 'Карина Куба',
            country: 'Россия',
          image: Img2021_3,
          text1: 'Карина Куба, бизнес-анархист, крестная мать тату-индустрии',
          text2: 'Основатель сети студий "Sorry Mam"',
          text3: 'Образовательной площадки "Период"',
          text4: 'Магазина расходки для мастеров "ВАГОН и маленькая тележка"',
          },
        ],
      },
      {
        year: 2022,
        items: [
          {
            name: 'Артемий Неумоин',
            country: 'Россия',
            image: Img2022_1,
            text1: 'Артемий Неумоин - мастер своего дела, воплощающий уникальные японские мотивы',
            text2: 'Его работы отличаются глубоким смыслом и тонким исполнением',
            text3: 'Его клиенты доверяют ему свое тело, зная, что результат превзойдет все ожидания',
            text4: 'Стаж работы более 15 лет',
          },
          {
            name: 'Бэн Клишевский',
            country: 'Украина',
            image: Img2022_2,
            text1: 'Мастер с опытом работы более 15ти лет в тату индустрии и бизнесе',
            text2: 'Основоположник направления космический реализм',
            text3: 'Член многочисленных мировых pro-team команд',
            text4: 'Создатель и владелец студии Banana tattoo',
          },
          {
            name: 'Егор Немшевич',
            country: 'Украина',
            image: Img2022_3,
            text1: 'Делает исключительно леттеринг, который плавно перерос в орнаментал',
            text2: 'Приезжает в города и дает групповые мастер классы',
            text3: 'Участник международных выставок, спикер конференций и форумов по тату',
            text4: 'Занимается тату 10 лет',
          },
        ],
      },
      {
        year: 2023,
        items: [
          {
            name: 'Денис Бедринский',
            country: 'Россия',
          image: Img2023_1,
          text1: 'Мастер чёрной готической неотрадиционной татуировки',
          text2: 'Представитель комьюнити  и член команды NEOTRADITIONAL RUSSIA RUSSIA2',
          text3: 'А так же представитель команд EZ TATTOO, KRASKA INK , TATTOODRUID, PEPAXOFFICIAL',
          text4: 'Опыт более 10 лет',
          },
          {
            name: 'Руслан Абусев',
            country: 'Россия',
            image: Img2023_2,
            text1: 'Татуировщик,специалист масштабных тату-проектов с 2015 года',
            text2: 'Изобретатель и конструктор революционных тату-игл и машин',
            text3: 'Основатель всемирно известного бренда ABUSEVTATTOO',
            text4: 'Многократный призер, а также судья фестиваля татуировки',
          },
          {
            name: 'Maksim Trad',
            country: 'Россия',
            image: Img2023_3,
            text1: 'Основатель студии electricdeathtattoo',
            text2: 'Приезжает в города и дает групповые мастер классы',
            text3: 'Неоднократный член жюри и победитель 20го тату фестиваля в номинации олд скул',
            text4: 'Опыт более 10 лет',
          },
        ],
      },
  ];

  export default data5;
