import Img2019_1 from '../img/jury/data3 2019_1.jpg'
import Img2019_2 from '../img/jury/data3 2019_2.jpg'
import Img2019_3 from '../img/jury/data3 2019_3.jpg'
import Img2020_1 from '../img/jury/data3 2020_1.jpg'
import Img2020_2 from '../img/jury/data3 2020_2.jpg'
import Img2020_3 from '../img/jury/data3 2020_3.jpg'
import Img2021_1 from '../img/jury/data3 2021_1.jpg'

const data3 = [
    {
      year: 2019,
      items: [
        {
          name: 'Татьяна Козарь',
          country: 'Россия',
          image: Img2019_1,
          text1: 'Косметолог, массажист, остеопат, химик-технолог в эстетической косметологии',
          text2: 'Основатель бренда «CozarSkin Cosmetics',
          text3: 'Победительница конкурса в амбасадор GiGi «Лучший косметолог в соцсетях»',
          text4: 'генеральный директор и тренер Dermalosophy',
          text5: 'Член сообщества предпринимателей индустрии красоты, автор методики «Релакс Боди Мускул и Лифтинг Мускул»',
        },
        {
            name: 'Анна Надрова',
            country: 'Беларусь',
          image: Img2019_2,
          text1: 'Действующий косметолог',
          text2: 'Призер международного конкурса по косметологии CIDESCO',
          text3: 'Двукратный призер II Всероссийского конкурса по эстетической косметологии и массажу Ethics',
          text4: 'Преподаватель базовых курсов по косметологии',
          text5: 'Стаж работы в косметологии более 6 лет',
        },
        {
            name: 'Светлана Баринова',
            country: 'Россия',
          image: Img2019_3,
          text1: 'Косметолог, кандидат физико-математических наук, специалист по восстановительной медицине',
          text2: 'Призер одного из первых конкурсов по косметологии в России «Искусство Красоты» 2002 год',
          text3: 'Преподаватель косметологии и авторских массажных техник',
          text4: 'Директор центра повышения квалификации косметологов “LACRIMA”',
          text5: 'Стаж работы в косметологии 35 лет',
        },
      ],
    },
    {
        year: 2020,
        items: [
          {
            name: 'Ирина Турухина',
            country: 'Украина',
            image: Img2020_1,
            text1: 'Косметолог, специалист по «естественному омоложению',
            text2: 'Тренер-преподаватель по косметологии',
            text3: 'Бренд-менеджер центра развития и повышения квалификации косметологов LACRIMA',
            text4: 'Обладатель премии в области депиляции «Тренер года 2019»',
            text5: 'Автор онлайн-курса “Секреты молодости»',
          },
          {
            name: 'Алиса Фаршатова',
            country: 'Россия',
            image: Img2020_2,
            text1: 'Косметолог, специалист по восстановительной медицине',
            text2: 'Член сборной России по косметологии',
            text3: 'Призёр международного конкурса по косметологии и эстетики CIDESCO',
            text4: 'Обладатель премии в области депиляции',
            text5: 'Стаж работы более 10 лет',
          },
          {
            name: 'Алина Никодимова',
            country: 'Россия',
            image: Img2020_3,
            text1: 'Действующий косметолог',
            text2: 'Двукратный победитель II Всероссийского конкурса по эстетической косметологии и массажу Ethics',
            text3: 'Врач с опытом работы в центрах реабилитации неврологических больных',
            text4: 'Преподаватель базовых курсов по косметологии и авторских методик по массажу лица',
            text5: 'Специалист и преподаватель в области эстетического кинезиотейпирования лица и тела',
          },
        ],
      },
      {
        year: 2021,
        items: [
          {
            name: 'Анна Надрова',
            country: 'Беларусь',
          image: Img2019_2,
          text1: 'Действующий косметолог',
          text2: 'Призер международного конкурса по косметологии CIDESCO',
          text3: 'Двукратный призер II Всероссийского конкурса по эстетической косметологии и массажу Ethics',
          text4: 'Преподаватель базовых курсов по косметологии',
          text5: 'Стаж работы в косметологии более 6 лет',
        },
          {
            name: 'Алиса Фаршатова',
            country: 'Россия',
            image: Img2020_2,
            text1: 'Косметолог, специалист по восстановительной медицине',
            text2: 'Член сборной России по косметологии',
            text3: 'Призёр международного конкурса по косметологии и эстетики CIDESCO',
            text4: 'Обладатель премии в области депиляции',
            text5: 'Стаж работы более 10 лет',
          },
          {
            name: 'Константин Фигловский',
            country: 'Россия',
            image: Img2021_1,
            text1: 'Врач-косметолог, преподаватель косметологии',
            text2: 'Руководитель и эксперт УЦ Аюна, главный косметолог сети студий красоты DPSP Epilier',
            text3: 'Лучший преподаватель аппаратных методик DORE AWARD 2020',
            text4: 'Лауреат Х международного конкурса косметологии и эстетики в номинации «Уход за кожей лица»',
            text5: 'Стаж работы в косметологии более 13 лет',
          },
        ],
      },
      {
        year: 2022,
        items: [
          {
            name: 'Татьяна Козарь',
            country: 'Россия',
            image: Img2019_1,
            text1: 'Косметолог, массажист, остеопат, химик-технолог в эстетической косметологии',
            text2: 'Основатель бренда «CozarSkin Cosmetics',
            text3: 'Победительница конкурса в амбасадор GiGi «Лучший косметолог в соцсетях»',
            text4: 'Генеральный директор и тренер Dermalosophy',
            text5: 'Член сообщества предпринимателей индустрии красоты, автор методики «Релакс Боди Мускул и Лифтинг Мускул»',
          },
          {
            name: 'Ирина Турухина',
            country: 'Украина',
            image: Img2020_1,
            text1: 'Косметолог, специалист по «естественному омоложению',
            text2: 'Тренер-преподаватель по косметологии',
            text3: 'Бренд-менеджер центра развития и повышения квалификации косметологов LACRIMA',
            text4: 'Обладатель премии в области депиляции «Тренер года 2019»',
            text5: 'Автор онлайн-курса “Секреты молодости»',
          },
          {
            name: 'Алина Никодимова',
            country: 'Россия',
            image: Img2020_3,
            text1: 'Действующий косметолог',
            text2: 'Двукратный победитель II Всероссийского конкурса по эстетической косметологии и массажу Ethics',
            text3: 'Врач с опытом работы в центрах реабилитации неврологических больных',
            text4: 'Преподаватель базовых курсов по косметологии и авторских методик по массажу лица',
            text5: 'Специалист и преподаватель в области эстетического кинезиотейпирования лица и тела',
          },
        ],
      },
      {
        year: 2023,
        items: [
          {
            name: 'Светлана Баринова',
            country: 'Россия',
          image: Img2019_3,
          text1: 'Косметолог, кандидат физико-математических наук, специалист по восстановительной медицине',
          text2: 'Призер одного из первых конкурсов по косметологии в России «Искусство Красоты» 2002 год',
          text3: 'Преподаватель косметологии и авторских массажных техник',
          text4: 'Директор центра повышения квалификации косметологов “LACRIMA”',
          text5: 'Стаж работы в косметологии 35 лет',
          },
          {
            name: 'Алиса Фаршатова',
            country: 'Россия',
            image: Img2020_2,
            text1: 'Косметолог, специалист по восстановительной медицине',
            text2: 'Член сборной России по косметологии',
            text3: 'Призёр международного конкурса по косметологии и эстетики CIDESCO',
            text4: 'Обладатель премии в области депиляции',
            text5: 'Стаж работы более 10 лет',
          },
          {
            name: 'Константин Фигловский',
            country: 'Россия',
            image: Img2021_1,
            text1: 'Врач-косметолог, преподаватель косметологии',
            text2: 'Руководитель и эксперт УЦ Аюна, главный косметолог сети студий красоты DPSP Epilier',
            text3: 'Лучший преподаватель аппаратных методик DORE AWARD 2020',
            text4: 'Лауреат Х международного конкурса косметологии и эстетики в номинации «Уход за кожей лица»',
            text5: 'Стаж работы в косметологии более 13 лет',
          },
        ],
      },
  ];

  export default data3;
